import { AiFillHtml5, AiOutlineConsoleSql } from 'react-icons/ai';
import { DiCss3, DiDotnet, DiPython, DiScrum } from 'react-icons/di';
import { FaReact, FaVuejs, FaAngular, FaGitAlt, FaGithub } from 'react-icons/fa';
import { TbBrandReactNative, TbAi } from 'react-icons/tb';
import { RiFlowChart } from 'react-icons/ri';
import { MdOutlineAutoStories } from 'react-icons/md';
import { ImLoop2 } from 'react-icons/im';
import { BiTestTube, BiLogoVisualStudio } from 'react-icons/bi';
import { VscAzure } from "react-icons/vsc";
import { HiSignal } from 'react-icons/hi2';
import { RxLoop } from "react-icons/rx";
import { PiMicrosoftExcelLogoFill, PiVectorThreeLight } from "react-icons/pi";
import {
  SiJavascript,
  SiTypescript,
  SiNodedotjs,
  SiEslint,
  SiDocker,
  SiFirebase,
  SiFigma,
  SiTwilio,
  SiMongodb,
  SiSelenium,
  SiFlutter,
  SiSvelte,
  SiGooglestreetview,
  SiJira,
  SiTrello,
  SiContentful,
  SiSanity,
  SiOpenai,
  SiHuggingface,
  SiMoq,
  SiLangchain,
  SiOllama,
  SiMyget,
  SiDotnet
} from 'react-icons/si';

import icon from '../assets/svg/Manypixels_Web_development_Outline.svg';
import ProgressBar from '../components/ProgressBar';
import Tool from '../components/Tool';

function Skills() {
  return (
    <div className="skills layout-child">
      <div className="anchor" id="skills"></div>
      <div className="layout-child__left">
        <h2>SKILLS</h2>
        <div className="layout-child__left__line"></div>
        <div className="layout-child__left__image">
          <img src={icon} alt="Code" />
        </div>
      </div>
      <div className="layout-child__right">
        <h3>Front-end</h3>
        <div className="skills__category">
          <ProgressBar name="HTML" prog="95" skillLevel={3} iconColor="#FF5722">
            <AiFillHtml5 />
          </ProgressBar>
          <ProgressBar name="CSS (SCSS)" prog="90" skillLevel={3} iconColor="#1572B6">
            <DiCss3 />
          </ProgressBar>
          <ProgressBar name="Javascript" prog="80" skillLevel={3} iconColor="#FFDF00">
            <SiJavascript />
          </ProgressBar>
          <ProgressBar name="Typescript" prog="50" skillLevel={3} iconColor="#2D79C7" skillText="Strongly typed 💖">
            <SiTypescript />
          </ProgressBar>
          <ProgressBar name="Angular" prog="5" skillLevel={3} iconColor="#DF2E31" skillText="Favorite framework">
            <FaAngular />
          </ProgressBar>
          <ProgressBar name="React" prog="80" skillLevel={2} iconColor="#00D9FF">
            <FaReact />
          </ProgressBar>
          <ProgressBar name="Svelte" prog="45" skillLevel={2} iconColor="#ff3e00">
            <SiSvelte />
          </ProgressBar>
          <ProgressBar name="Vue.js" prog="20" skillLevel={0} iconColor="#41B883" skillText="">
            <FaVuejs />
          </ProgressBar>
          <ProgressBar name=".NET MVC" prog="45" skillLevel={2} iconColor="#712b86">
            <DiDotnet />
          </ProgressBar>
        </div>
        <h3>Back-end</h3>
        <div className="skills__category">
          <ProgressBar name=".NET Web API" prog="45" skillLevel={3} iconColor="#712b86" skillText="My go-to">
            <SiDotnet />
          </ProgressBar>
          <ProgressBar name="Flask (Python)" prog="70" skillLevel={2} iconColor="#3771A1">
            <DiPython />
          </ProgressBar>
          <ProgressBar name="Express.js" prog="55" skillLevel={1} iconColor="#549F41">
            <SiNodedotjs />
          </ProgressBar>
        </div>
        <h3>Mobile</h3>
        <div className="skills__category">
          <ProgressBar name="Flutter" prog="50" skillLevel={1} iconColor="#0468d7">
            <SiFlutter />
          </ProgressBar>
          <ProgressBar name="React Native" prog="50" skillLevel={1} iconColor="#00D9FF">
            <TbBrandReactNative />
          </ProgressBar>
        </div>
        <h3>Other noteworthy skills/tools</h3>
        <div className="skills__category-tools">
          <Tool name="Agile dev." icon={<DiScrum />} />
          <Tool name="Azure DevOps" icon={<RxLoop />} />
          <Tool name="Flowcharts" icon={<RiFlowChart />} />
          <Tool name="Figma" icon={<SiFigma />} />
          <Tool name="GitHub" icon={<FaGithub />} />
          <Tool name="Jira" icon={<SiJira />} />
          <Tool name="Trello" icon={<SiTrello />} />
          <Tool name="User Stories" icon={<MdOutlineAutoStories />} />
          <div className="skills__category-tools__d"></div>
          <Tool name="xUnit & Moq" icon={<SiMoq />} />
          <Tool name="Playwright" icon={<BiTestTube />} />
          <Tool name="Selenium" icon={<SiSelenium />} />
          <div className="skills__category-tools__d"></div>
          <Tool name="Azure" icon={<VscAzure />} />
          <Tool name="CI/CD" icon={<ImLoop2 />} />
          <Tool name="Contentful" icon={<SiContentful />} />
          <Tool name="Docker" icon={<SiDocker />} />
          <Tool name="Eslint" icon={<SiEslint />} />
          <Tool name="Firebase" icon={<SiFirebase />} />
          <Tool name="Git" icon={<FaGitAlt />} />
          <Tool name="Google Maps API" icon={<SiGooglestreetview />} />
          <Tool name="MongoDB" icon={<SiMongodb />} />
          <Tool name="MyGet" icon={<SiMyget />} />
          <Tool name="MS Office" icon={<PiMicrosoftExcelLogoFill />} />
          <Tool name="RxJS" icon={<HiSignal />} />
          <Tool name="Sanity" icon={<SiSanity />} />
          <Tool name="SQL" icon={<AiOutlineConsoleSql />} />
          <Tool name="Twilio" icon={<SiTwilio />} />
          <Tool name="VSCode" icon={<BiLogoVisualStudio />} />
          <div className="skills__category-tools__d"></div>
          <Tool name="Huggingface" icon={<SiHuggingface />} />
          <Tool name="LangChain" icon={<SiLangchain />} />
          <Tool name="Mistral AI API" icon={<TbAi />} />
          <Tool name="Ollama" icon={<SiOllama />} />
          <Tool name="OpenAI API" icon={<SiOpenai />} />
          <Tool name="Vector Storage" icon={<PiVectorThreeLight />} />
        </div>
      </div>
    </div>
  );
}

export default Skills;
