// eslint-disable-next-line no-unused-vars
function ProgressBar({ name, children, skillLevel, iconColor = 'var(--main-color)', skillText = "" }) {
  // const styleProgWidth = {
  //   width: `calc(0px + ${prog}%`,
  // };

  // const skillLevels = ['Novice', 'Basic', 'Competent', 'Proficient'];

  return (
    <div className="comp_progressbar">
      <div className="comp_progressbar__icon" style={{ color: iconColor }}>
        {children}
      </div>
      <div className="comp_progressbar__label">{name}</div>
      {/* <div className="comp_progressbar__level">{skillLevels[skillLevel]}</div> */}
      <div className="comp_progressbar__level">{skillText}</div>
      {/* <div className="comp_progressbar__outer">
        <div className="comp_progressbar__inner" style={styleProgWidth}></div>
        <div className="comp_progressbar__2550"></div>
        <div className="comp_progressbar__75"></div>
      </div> */}
    </div>
  );
}

export default ProgressBar;
