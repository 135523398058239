import { useState } from 'react';
import { BiCollapse, BiExpand } from 'react-icons/bi';
import { DiDotnet, DiPython } from 'react-icons/di';
import { FaRaspberryPi, FaReact } from 'react-icons/fa';
import { ImLoop2 } from 'react-icons/im';
import { SiSelenium } from 'react-icons/si';
import { VscAzure } from "react-icons/vsc";
import imgHeatpump1 from '../assets/images/portfolio/heatpump/1.jpg';
import imgHeatpump2 from '../assets/images/portfolio/heatpump/2.jpg';
import imgHeatpump3 from '../assets/images/portfolio/heatpump/3.jpg';
import imgWedding1 from '../assets/images/portfolio/wedding/1.png';
import imgWedding2 from '../assets/images/portfolio/wedding/2.png';

function ExpandCard(cardNr) {
  const card = document.querySelector(`.projects-card_${cardNr}`);
  const isToggled = card.getAttribute('toggled');
  const body = card.querySelector('.projects-card__body');
  const toggleButtonExpand = card.querySelector('.projects-card__expand-btn');
  const toggleButtonCollapse = card.querySelector('.projects-card__expand-btn--close');

  if (isToggled === 'true') {
    // Close
    const scrollDistance = -Math.abs(Math.round(card.getBoundingClientRect().height) - 450);
    window.scrollBy(0, scrollDistance);
    toggleButtonCollapse.style.display = 'none';
    setTimeout(() => {
      card.setAttribute('toggled', 'false');
      card.classList.remove('projects-card--expanded');
      body.classList.remove('projects-card__body--visible');
      setTimeout(() => {
        toggleButtonExpand.style.display = 'block';
      }, 890);
      setTimeout(() => {
        body.classList.add('projects-card__body--hidden');
      }, 890);
    }, 300);
  } else {
    // Open
    card.setAttribute('toggled', 'true');
    card.classList.add('projects-card--expanded');
    body.classList.remove('projects-card__body--hidden');
    setTimeout(() => {
      body.classList.add('projects-card__body--visible');
    }, 350);
    toggleButtonExpand.style.display = 'none';
    setTimeout(() => {
      toggleButtonCollapse.style.display = 'block';
    }, 400);
  }
}

function Projects() {
  return (
    <>
      <div className="projects-card projects-card_1">
        <h3>Heat Pump Controller (2022)</h3>
        <div className="projects-card__icons">
          <DiPython style={{ color: '#3771A1', fontSize: '1.15em' }} title="Flask v2.2" />
          <FaReact style={{ color: '#00D9FF', margin: '0 .5rem 0 .3rem' }} title="React v18" />
          <SiSelenium style={{ color: '#43B02A', fontSize: '.95em' }} title="Selenium v4.6" />
          <FaRaspberryPi
            style={{ color: 'rgb(205 33 83)', marginLeft: '.5rem' }}
            title="Raspberry Pi"
          />
        </div>
        <div className="projects-card__body projects-card__body--hidden">
          <PorojectCardHeatpumpControllerBody />
        </div>
        <div className="projects-card__expand-btn" onClick={() => ExpandCard(1)}>
          <BiExpand />
          <span>Details</span>
        </div>
        <div
          className="projects-card__expand-btn projects-card__expand-btn--close"
          onClick={() => ExpandCard(1)}
          style={{ display: 'none' }}
        >
          <BiCollapse />
          <span>Close</span>
        </div>
      </div>
      <div className="projects-card projects-card_2">
        <h3>Wedding Website (2023)</h3>
        <div className="projects-card__icons">
          <DiDotnet style={{ color: '#712b86', fontSize: '1.15em' }} title=".NET 7 WebAPI" />
          <FaReact style={{ color: '#00D9FF', margin: '0 .5rem 0 .4rem' }} title="React v18" />
          <ImLoop2 style={{ color: 'var(--main-color', fontSize: '.9em' }} title="CI/CD" />
          <VscAzure
            style={{ color: '#0089D7', marginLeft: '.55rem', fontSize: '.95em' }}
            title="Azure"
          />
        </div>
        <div className="projects-card__body projects-card__body--hidden">
          <PorojectCardWeddingWebsiteBody />
        </div>
        <div className="projects-card__expand-btn" onClick={() => ExpandCard(2)}>
          <BiExpand />
          <span>Details</span>
        </div>
        <div
          className="projects-card__expand-btn projects-card__expand-btn--close"
          onClick={() => ExpandCard(2)}
          style={{ display: 'none' }}
        >
          <BiCollapse />
          <span>Close</span>
        </div>
      </div>
      {/* <div className="projects-card projects-card_3">
        <h3>Truck Times</h3>
        <div className="projects-card__icons">
          <DiDotnet style={{ color: '#712b86', fontSize: '1.15em' }} title=".NET 7 WebAPI" />
          <FaAngular style={{ color: '#DF2E31', margin: '0 .3rem ' }} title="Angular v16" />
          <ImLoop2 style={{ color: 'var(--main-color', fontSize: '.9em' }} title="CI/CD" />
          <SiMicrosoftazure
            style={{ color: '#0089D7', marginLeft: '.55rem', fontSize: '.95em' }}
            title="Azure"
          />
        </div>
        <div className="projects-card__body projects-card__body--hidden">
          <PorojectCardTruckTimesBody />
        </div>
        <div className="projects-card__expand-btn" onClick={() => ExpandCard(3)}>
          <BiExpand />
          <span>Details</span>
        </div>
        <div
          className="projects-card__expand-btn projects-card__expand-btn--close"
          onClick={() => ExpandCard(3)}
          style={{ display: 'none' }}
        >
          <BiCollapse />
          <span>Close</span>
        </div>
      </div> */}
    </>
  );
}

function PorojectCardHeatpumpControllerBody() {
  const [imgNr, setImgNr] = useState(0);

  const imagesHP = [
    { image: imgHeatpump1, desc: 'Landing page with general data from the pump.' },
    {
      image: imgHeatpump2,
      desc: 'The scheduling feature where you can set temperature-offset for the radiators and toggle hot water switch based on day and time.',
    },
    { image: imgHeatpump3, desc: 'Diagram showing the electricity prices by the hour.' },
  ];

  function NextPic(el) {
    const img = el.parentElement.parentElement.querySelector('.projects-card__body__img');

    if (imgNr === imagesHP.length - 1) {
      setImgNr(0);
    } else {
      setImgNr(imgNr + 1);
    }

    img.src = imagesHP[imgNr].image;
  }

  function PrevPic(el) {
    const img = el.parentElement.parentElement.querySelector('.projects-card__body__img');

    if (imgNr === 0) {
      setImgNr(imagesHP.length - 1);
    } else {
      setImgNr(imgNr - 1);
    }

    img.src = imagesHP[imgNr].image;
  }

  return (
    <>
      <h4>The Idea</h4>
      <p>
        With the escalating electricity prices in Europe during 2022 I needed a way to reduce the
        power consumption of our house.
      </p>
      <br />
      <p>
        After doing some power readings I was convinced that our geothermal heat pump stood for
        around 80% of our total consumption during the colder parts of the year.
      </p>
      <br />
      <p>
        I found an open-source project by a guy from Latvia who had made a simple API to communicate
        with pumps of the brand that I have. I knew I wanted a way to control the pump based on both
        in- and outdoor temperature and the current electricity price. Another feature would be
        optional scheduling.
      </p>
      <br />
      <p>
        So the next step was to start expanding the API, create a back-end REST API with cronjobs,
        data collection, databases, etc. And a front-end application adapted for phones consuming
        the new REST API.
      </p>
      <br />
      <br />
      <h4>Featured Tools</h4>
      <ul>
        <li>
          <mark>Flask</mark> (Python) for back-end.
        </li>
        <li>
          <mark>React</mark> for front-end.
        </li>
        <li>
          <mark>Selenium</mark> for web scraping electricity prices.
        </li>
        <li>
          <mark>SMHI WeatherAPI</mark> to get weather data.
        </li>
        <li>
          <mark>Raspberry Pi</mark> for hosting.
        </li>
      </ul>
      <br />
      <br />
      <h4>Result</h4>
      <p>
        I made it into a mobile <b>P</b>rogressive<b>W</b>eb<b>A</b>pp with everything hosted on a
        Raspberry Pi on my local network.
      </p>
      <br />
      <p>
        During 2022 we were able to reduce our power consumption from 17.016 kWh to 11.809 kWh!
        <br /> A whopping
        <mark> 31% reduction</mark> to our power consumption, largely thanks to this application!
      </p>
      <br />
      <br />
      <h4>Images</h4>
      <br />
      <div className="projects-card__body__imgcontainer">
        <img
          src={imagesHP[imgNr].image}
          alt="img"
          className="projects-card__body__img projects-card__body__img--mobile"
        />
      </div>
      <p className="projects-card__body__imgdesc">{imagesHP[imgNr].desc}</p>
      <div className="projects-card__body__imgbtn">
        <button type="button" onClick={(e) => PrevPic(e.target)}>
          {'<'}
        </button>
        <span>
          {imgNr + 1} / {imagesHP.length}
        </span>
        <button type="button" onClick={(e) => NextPic(e.target)}>
          {'>'}
        </button>
      </div>
    </>
  );
}

function PorojectCardWeddingWebsiteBody() {
  const [imgNr, setImgNr] = useState(0);

  const imagesHP = [
    { image: imgWedding1, desc: 'Landing page on desktop.' },
    {
      image: imgWedding2,
      desc: 'Attendance form on desktop.',
    },
  ];

  function NextPic(el) {
    const img = el.parentElement.parentElement.querySelector('.projects-card__body__img');

    if (imgNr === imagesHP.length - 1) {
      setImgNr(0);
    } else {
      setImgNr(imgNr + 1);
    }

    img.src = imagesHP[imgNr].image;
  }

  function PrevPic(el) {
    const img = el.parentElement.parentElement.querySelector('.projects-card__body__img');

    if (imgNr === 0) {
      setImgNr(imagesHP.length - 1);
    } else {
      setImgNr(imgNr - 1);
    }

    img.src = imagesHP[imgNr].image;
  }

  return (
    <>
      <h4>The Goal</h4>
      <p>
        There are already a ton of website builders that could generate what this website does. But
        the reason I did it myself was to get better at .NET WebAPI.
      </p>
      <br />
      <p>The features of this site, apart from general information, are:</p>
      <ul style={{ listStyle: 'circle', marginTop: '.3rem' }}>
        <li>Attendance form</li>
        <li>Administration page</li>
        <li>Wishlist with reserve feature</li>
        <li>Automatic emails from private domain</li>
      </ul>
      <br />
      <br />
      <h4>Featured Tools</h4>
      <ul>
        <li>
          <mark>.NET 7 WebAPI</mark> for back-end.
        </li>
        <li>
          <mark>React</mark> for front-end.
        </li>
        <li>
          <mark>CI/CD</mark> with GitHub Actions, deploying directly to Azure upon main branch
          update.
        </li>
        <li>
          <mark>Azure</mark> for hosting with "Static Web App" for front-end and "App Service" for
          back-end.
        </li>
      </ul>
      <br />
      <br />
      <h4>Result</h4>
      <p>I'm very happy with the visual result, both on mobile, tablet and desktop.</p>
      <br />
      <p>
        The back-end was a bit chaotic at fist. But while learning I refactored the code twice to
        implement, and make better use of, DTOs, Repositories, UnitOfWork, AutoMapper, etc.
      </p>
      <br />
      <h4>Images</h4>
      <br />
      <div className="projects-card__body__imgcontainer">
        <img
          src={imagesHP[imgNr].image}
          alt="img"
          className="projects-card__body__img projects-card__body__img--pc"
        />
      </div>
      <p className="projects-card__body__imgdesc">{imagesHP[imgNr].desc}</p>
      <div className="projects-card__body__imgbtn">
        <button type="button" onClick={(e) => PrevPic(e.target)}>
          {'<'}
        </button>
        <span>
          {imgNr + 1} / {imagesHP.length}
        </span>
        <button type="button" onClick={(e) => NextPic(e.target)}>
          {'>'}
        </button>
      </div>
    </>
  );
}

// function PorojectCardTruckTimesBody() {
//   return (
//     <>
//       <h4>The Task</h4>
//       <p>
//         A client in logistics tasked me with creating a web application for truck drivers to
//         register their arrival and departure times for different locations and let the traffic
//         planners know if the trucks were on time or not.
//       </p>
//       <br />
//       <p>
//         After brainstorming we decided that the best way to do this was for the drivers to use QR
//         codes to register their arrival and departure times.
//       </p>
//       <br />
//       Some of the features of this application:
//       <ul style={{ listStyle: 'circle', marginTop: '.3rem' }}>
//         <li>Managing and planning the truck routes with locations, suppliers etc.</li>
//         <li>QR Code and PDF generation</li>
//         <li>Dashboard and statistic pages to manage and get an overview of all the data</li>
//         <li>Excel sheet generation</li>
//         <li>Charts with Chart.js</li>
//         <li>GPS positions</li>
//         <li>Maps with Leaflet and OpenStreetMaps</li>
//         <li>Authorization with IdentityFrameworkCore</li>
//       </ul>
//       <br />
//       <p>
//         I'm very satisfied with the result and was able to improve my skills using
// Angular and .NET
//         WebAPI as well as dive deeper into Azure.
//       </p>
//       <br />
//       <br />
//       <h4>Main Tools</h4>
//       <ul>
//         <li>
//           <mark>.NET 7 WebAPI</mark> for back-end.
//         </li>
//         <li>
//           <mark>Angular</mark> for front-end.
//         </li>
//       </ul>
//       <br />
//       <div className="projects-card__body__imgcontainer">
//         <img
//           src={imgTruckTimes}
//           alt="img"
//           className="projects-card__body__img projects-card__body__img--pc"
//         />
//       </div>
//     </>
//   );
// }

export default Projects;
