import { TbArrowUpCircle } from "react-icons/tb";
import icon from "../assets/svg/Manypixels_Exp_Outline.svg";


function Experience() {
  return (
    <div className="experience layout-child">
      <div className="anchor" id="experience"></div>
      <div className="layout-child__left">
        <h2>Experience</h2>
        <div className="layout-child__left__line"></div>
        <div className="layout-child__left__image">
          <img src={icon} alt="Experience" />
        </div>
      </div>
      <div className="layout-child__right ">
        <div className="experience__container">
          <h3>Any4m</h3>
          <p className="experience__container__title">Software Developer</p>
          <div>2023 - now</div>
          <p className="experience__container__summary">I started with an internship that quickly transitioned into a part-time job while I completed my studies. Once my studies were complete, I began working full-time at Any4m.</p>
          <br />
          <br />
          <br />
          <h3>Self employed (side business)</h3>
          <p className="experience__container__title">Consultant</p>
          <div>2023 - now</div>
          <p className="experience__container__summary">Data Analytics and Programming.</p>
          <br />
          <br />
          <br />
          <h3>Bring</h3>
          <p className="experience__container__title">Product Owner</p>
          <div>2021 - 2022</div>
          <p className="experience__container__summary">(TMS) Traffic Management System for linehaul traffic.</p>
          <TbArrowUpCircle />
          <p className="experience__container__title">Traffic Impovements</p>
          <div>2019 - 2022</div>
          <p className="experience__container__summary">LEAN, Excel, Programming, Economy, and alot more.</p>
          <TbArrowUpCircle />
          <p className="experience__container__title">Traffic Operator</p>
          <div>2016 - 2019</div>
          <p className="experience__container__summary">Managing linehaul traffic in Sweden, Norway, Denmark & Finland.</p>
          <TbArrowUpCircle />
          <p className="experience__container__title">Terminal Worker</p>
          <div>2011 - 2016</div>
          <p className="experience__container__summary">During these years i was mainly working night shift driving forklift loading/unloading trucks.</p>
        </div>
      </div>
    </div>
  );
}

export default Experience;
